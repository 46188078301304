import { splitLayers } from './stylable-shorthands';

const COMMENT_START = '/*';
const COMMENT_END = '*/';
const VALUE_WITHIN_COMMENT_REGEX = /\/\*.*\*\//g;
const COMMENT_START_REGEX = /\/\*/g;
const COMMENT_END_REGEX = /\*\//g;

export class StylableComments {
    public indicesValid = false;
    private commentIndices: number[] = [];

    constructor(value: string) {
        const commentIndices = layerCommentIndices(splitLayers(value));
        if (commentIndices !== null) {
            this.commentIndices = commentIndices;
            this.indicesValid = true;
        }
    }

    public isCommented(index: number) {
        return !!~this.commentIndices.indexOf(index);
    }

    public replaceLayers(srcIndex: number, dstIndex: number) {
        if (this.commentIndices.length === 0) {
            return;
        }

        const srcIndexComment = this.commentIndices.indexOf(srcIndex);
        const dstIndexComment = this.commentIndices.indexOf(dstIndex);
        if (!!~srcIndexComment && !~dstIndexComment) {
            this.commentIndices[srcIndexComment] = dstIndex;
        } else if (!!~dstIndexComment && !~srcIndexComment) {
            this.commentIndices[dstIndexComment] = srcIndex;
        }
    }

    public duplicateLayer(index: number) {
        if (this.commentIndices.length === 0) {
            return;
        }

        for (let i = 0; i < this.commentIndices.length; i++) {
            if (this.commentIndices[i] > index) {
                this.commentIndices[i]++;
            }
        }

        const commentIndex = this.commentIndices.indexOf(index);
        if (~commentIndex) {
            this.commentIndices.splice(commentIndex, 0, index + 1);
        }
    }

    public removeLayer(index: number) {
        if (this.commentIndices.length === 0) {
            return;
        }

        const commentIndex = this.commentIndices.indexOf(index);
        if (~commentIndex) {
            this.commentIndices.splice(commentIndex, 1);
        }

        for (let i = 0; i < this.commentIndices.length; i++) {
            if (this.commentIndices[i] > index) {
                this.commentIndices[i]--;
            }
        }
    }

    public toggleCommentLayer(index: number) {
        const commentIndex = this.commentIndices.indexOf(index);
        if (~commentIndex) {
            this.commentIndices.splice(commentIndex, 1);
        } else {
            this.commentIndices.push(index);
        }
    }

    public setLayerComments(value: string) {
        if (this.commentIndices.length === 0) {
            return value;
        }

        const layers = splitLayers(value);
        let startOfCommentBlock = -1;
        let commentBlockOpen = false;
        for (let i = 0; i < layers.length; i++) {
            commentBlockOpen = startOfCommentBlock !== -1;
            if (this.isCommented(i)) {
                if (!commentBlockOpen) {
                    layers[i] = COMMENT_START + layers[i];
                    startOfCommentBlock = i;
                }
            } else if (commentBlockOpen) {
                layers[i] = COMMENT_END + layers[i];
                startOfCommentBlock = -1;
            }
        }

        commentBlockOpen = startOfCommentBlock !== -1;
        if (commentBlockOpen) {
            if (startOfCommentBlock > 0) {
                layers[startOfCommentBlock - 1] = layers[startOfCommentBlock - 1] + COMMENT_START;
                layers[startOfCommentBlock] = layers[startOfCommentBlock].slice(2);
            }
            layers[layers.length - 1] = layers[layers.length - 1] + COMMENT_END;
        }

        return layers.join(', ');
    }
}

// TODO: Make a version that is wrapped with splitLayers and gets a string value?
export function layerCommentIndices(layers: string[]) {
    if (layers.length === 0) {
        return null;
    }

    const indices: number[] = [];

    let inCommentBlock = false;
    for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];

        const commentOpenIndex = layer.indexOf(COMMENT_START);
        const commentCloseIndex = layer.indexOf(COMMENT_END);
        const commentOpens = commentOpenIndex !== -1;
        const commentCloses = commentCloseIndex !== -1;
        const commentOpensAtLineStart = commentOpenIndex === 0;
        const commentClosesAtLineStart = commentCloseIndex === 0;
        const commentOpensAtLineEnd = commentOpenIndex === layer.length - COMMENT_START.length;
        const commentClosesAtLineEnd = commentCloseIndex === layer.length - COMMENT_END.length;
        const commentOpensInsideLine = !commentOpensAtLineStart && !commentOpensAtLineEnd;
        const commentClosesInsideLine = !commentClosesAtLineStart && !commentClosesAtLineEnd;

        if (layers.length === 1 && commentOpensAtLineStart && commentClosesAtLineEnd) {
            return [i];
        }

        if ((inCommentBlock && !commentClosesAtLineStart) || (!inCommentBlock && commentOpensAtLineStart)) {
            indices.push(i);
        }

        if (commentCloses) {
            if (!inCommentBlock || commentOpensAtLineStart || commentClosesInsideLine) {
                return null;
            }
            inCommentBlock = false;
        }

        if (commentOpens) {
            if (inCommentBlock || (commentCloses && !commentClosesAtLineStart) || commentOpensInsideLine) {
                return null;
            }
            inCommentBlock = true;
        }
    }

    return !inCommentBlock ? indices : null;
}

export function filterComments(value: string) {
    return value.replace(VALUE_WITHIN_COMMENT_REGEX, '');
}

export function removeComments(value: string) {
    return value.replace(COMMENT_START_REGEX, '').replace(COMMENT_END_REGEX, '');
}
