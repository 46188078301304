import type { ElementTree, DynamicPartControllerSelectorValues, DynamicPartInputSelectorValues } from '../types';

export function elementTreeSelectors(elements: ElementTree) {
    const selectors: string[] = [];

    const walkTree = (root: ElementTree, parentSelector?: string) => {
        Object.keys(root).forEach((node) => {
            const currSelector = parentSelector ? `${parentSelector}::${node}` : node;
            selectors.push(currSelector);

            walkTree(root[node], currSelector);
        });
    };

    walkTree(elements);

    return selectors;
}
export function getDynamicPartInputSelectorValues(
    selectorValues: DynamicPartControllerSelectorValues,
    dynamicPart: string
) {
    return Object.keys(selectorValues).reduce((outputSelectorValues, selector) => {
        const controllerSelector = selectorValues[selector];
        const controllerMatch = controllerSelector.controllersMatch.find(
            (controller) => controller.name === dynamicPart
        );
        if (controllerMatch) {
            outputSelectorValues[selector] = {
                params: controllerMatch.params,
                decls: controllerSelector.decls,
            };
        }
        return outputSelectorValues;
    }, {} as DynamicPartInputSelectorValues);
}
