import { removeDuplicates } from './general-utils';

type Func = () => void;

export const uniteCallbacks = (callbacks: Func[]): (() => void) => {
    return () => {
        removeDuplicates(callbacks).forEach((cb: Func) => {
            cb();
        });
    };
};
