import type { DeclarationMap } from '../../../types';
import type { AnnotatedCssTreeNode, LonghandGroup, LonghandGroupArray } from './shorthand-expand-types';
import { cssGrouperConfig } from './shorthand-expand-config';
import { parse, generate, CssNode, Value } from 'css-tree';

export function tokenizeProp(value: string): Value {
    return parse(value, { context: 'value' }) as Value;
}

export function generateCssFromAst(value: CssNode) {
    return generate(value);
}

/*
receives an annotated css ast and returns an array of groups sorted by classification (annotation)
 */
export function groupAnnotatedAstByTag(ast: Value): LonghandGroupArray<AnnotatedCssTreeNode> {
    const groups: LonghandGroupArray<CssNode> = [];

    ast.children &&
        ast.children.forEach((child: AnnotatedCssTreeNode) => {
            const classification = child.classification;
            if (child.type === 'WhiteSpace' || child.type === 'Operator') {
                return;
            }
            if (classification === undefined) {
                groups.push({ nodes: [child] });
            } else {
                const nodeGroup = groups.find((group) => group.classification === classification);
                if (nodeGroup) {
                    // Node group exists
                    nodeGroup.nodes.push(child);
                } else {
                    groups.push({ nodes: [child], classification });
                }
            }
        });

    return groups;
}

/*
expands a css prop using internal logic (non-browser dependent), if a config entry is available for that prop
 */
export function propExpander(prop: string, value: string): DeclarationMap | undefined {
    const matchingProp = cssGrouperConfig.find((expand) => {
        return expand.prop === prop;
    });

    if (matchingProp === undefined) {
        return undefined;
    }

    const node = tokenizeProp(value);

    const annotatedAst = matchingProp.annotate(node);
    const groups = groupAnnotatedAstByTag(annotatedAst);
    const declarationMap = groupExtractor(groups);
    if (matchingProp.transform !== undefined) {
        return matchingProp.transform(declarationMap);
    } else {
        return declarationMap;
    }
}

/*
Extracts DeclarationMap from annotated css groups by just joining all the values
 */
export function groupExtractor(groups: LonghandGroupArray<CssNode>): DeclarationMap {
    const result: DeclarationMap = {};

    groups.forEach((group: LonghandGroup<CssNode>) => {
        if (group.classification !== undefined) {
            const prop: string[] = [];
            group.nodes.forEach((part: CssNode) => {
                prop.push(generateCssFromAst(part));
            });
            result[group.classification] = prop.join(' ');
        }
    });

    return result;
}
