import * as postcss from 'postcss';

import { StylableShorthands } from '../../../stylable-shorthands';
import type { EvalDeclarationValue } from '../../../types';
import { DEFAULT_EVAL_DECLARATION_VALUE } from '../../../stylable-site-vars';

export interface BackgroundImage {
    background?: string;
    ['background-image']?: string;
    ['background-position']?: string;
    ['background-repeat']?: string;
    ['background-size']?: string;
}

export const DEFAULT_BACKGROUND_SIZE = 'cover';
export const DEFAULT_BACKGROUND_IMAGE_REPEAT = 'no-repeat';
export const DEFAULT_BACKGROUND_IMAGE_PATTERN_REPEAT = 'repeat';

export function parseBackgroundImage(
    imageLayer: string | undefined,
    evalDeclarationValue: EvalDeclarationValue = DEFAULT_EVAL_DECLARATION_VALUE
): BackgroundImage {
    if (imageLayer === undefined) {
        return {};
    }

    const shorthand = new StylableShorthands(evalDeclarationValue);
    const expandedWithoutDefaults = shorthand.expandDeclaration(
        postcss.decl({ prop: 'background', value: imageLayer }),
        {},
        false
    );
    const expandedWithDefaults = shorthand.expandDeclaration(postcss.decl({ prop: 'background', value: imageLayer }));

    if (!expandedWithoutDefaults['background-position']) {
        expandedWithDefaults['background-position'] = '50% 50%';
    }
    if (!expandedWithoutDefaults['background-repeat']) {
        expandedWithDefaults['background-repeat'] = DEFAULT_BACKGROUND_IMAGE_PATTERN_REPEAT;
    }

    return expandedWithDefaults;
}
