import { nameToHex } from './colors';

export const universalCssEnumOptions = ['inherit', 'initial', 'unset'];

export function cssEnumOptions(prop: string): string[] {
    const options = universalCssEnumOptions;
    switch (prop) {
        case 'color':
        case 'background-color':
        case 'border-color':
        case 'border-bottom-color':
        case 'border-left-color':
        case 'border-right-color':
        case 'border-top-color':
        case 'outline-color':
            return Object.keys(nameToHex).concat(['currentColor']).concat(options);
        case 'cursor':
            return [
                'auto',
                'default',
                'none',
                'context-menu',
                'help',
                'pointer',
                'progress',
                'wait',
                'cell',
                'crosshair',
                'text',
                'vertical-text',
                'alias',
                'copy',
                'move',
                'no-drop',
                'not-allowed',
                'e-resize',
                'n-resize',
                'ne-resize',
                'nw-resize',
                's-resize',
                'se-resize',
                'sw-resize',
                'w-resize',
                'ew-resize',
                'ns-resize',
                'nesw-resize',
                'nwse-resize',
                'col-resize',
                'row-resize',
                'all-scroll',
                'zoom-in',
                'zoom-out',
                'grab',
                'grabbing',
            ].concat(options);
        case 'direction':
            return ['ltr', 'rtl'].concat(options);
        case 'display':
            return [
                'none',
                'inline',
                'block',
                'inline-block',
                'list-item',
                'table',
                'inline-table',
                'table-row-group',
                'table-header-group',
                'table-footer-group',
                'table-row',
                'table-column-group',
                'table-column',
                'table-cell',
                'table-caption',
            ].concat(options);
        case 'float':
            return ['none', 'left', 'right'].concat(options);
        case 'font-size':
            return [
                'xx-small',
                'x-small',
                'small',
                'medium',
                'large',
                'x-large',
                'xx-large',
                'smaller',
                'larger',
            ].concat(options);
        case 'outline':
            return ['none', 'solid', 'dotted', 'dashed', 'double', 'groove', 'ridge', 'inset', 'outset'].concat(
                options
            );
        case 'overflow':
            return ['visible', 'hidden', 'scroll', 'auto'].concat(options);
        case 'position':
            return ['static', 'relative', 'absolute', 'fixed'].concat(options);
        case 'text-align':
            return ['left', 'right', 'center', 'justify'].concat(options);
        case 'text-decoration':
            return ['none', 'underline', 'overline', 'line-through'].concat(options);
        case 'text-transform':
            return ['none', 'uppercase', 'lowercase', 'capitalize'].concat(options);
    }
    return options;
}
