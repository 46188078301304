import { nativePseudoElements } from '@stylable/core';

export const nativePseudoElementsSet = new Set(nativePseudoElements);

export function stripNativePseudoFromSelector(str: string) {
    for (const name of nativePseudoElements) {
        const pseudo = '::' + name;
        if (str.endsWith(pseudo)) {
            return str.slice(0, 0 - pseudo.length);
        }
    }
    return str;
}
