export class BrowserComputeCss {
    private volatileElement: HTMLElement;

    constructor(prop: string, value: string) {
        this.volatileElement = document.createElement('style-sandbox-element');
        this.volatileElement.style.setProperty(prop, value);
    }

    public getLonghand(longhandProp: string): string {
        const propertyValue = this.volatileElement.style.getPropertyValue(longhandProp);
        if (propertyValue.toLowerCase() === 'currentcolor') {
            // Workaround for Edge15
            return 'currentcolor';
        }
        return propertyValue;
    }
}
