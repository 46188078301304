import type * as postcss from 'postcss';

const revisionSymbol = '$r';
const IdSymbol = '$id';
type Mutable = {
    $r: number;
    $id: number;
} & postcss.Node;
let revision = 0;
let id = 0;

export function getNodeRevision(node: postcss.Node): number {
    const mutableNode = node as Mutable;
    if (mutableNode[revisionSymbol] === undefined) {
        initNodeRevisionDeep(mutableNode);
    }
    return mutableNode[revisionSymbol];
}

export function updateNodeRevision(node: postcss.Node): number {
    revision++;
    updateNodeAndParentsRevision(node);
    return revision;
}

export function getNodeId(node: postcss.Node): number {
    const mutableNode = node as Mutable;
    if (mutableNode[IdSymbol] === undefined) {
        mutableNode[IdSymbol] = ++id;
    }
    return mutableNode[IdSymbol];
}

function initNodeRevisionDeep(node: postcss.Node): void {
    const mutableNode = node as Mutable;
    mutableNode[revisionSymbol] = revision;
    if (node.type === 'rule' || node.type === 'root' || node.type === 'atrule') {
        (node as postcss.Rule | postcss.Root | postcss.AtRule).walk((childNode) => {
            (childNode as any)[revisionSymbol] = revision; // ToDo: not update if exist
        });
    }
}

function updateNodeAndParentsRevision(node: postcss.Node): void {
    let current: postcss.Node | void = node;
    do {
        (current as Mutable)[revisionSymbol] = revision; // ToDo: stop if already at revision
        current = current.parent;
    } while (current);
}
