export function doesEndWithState(newSelector: string) {
    return newSelector.lastIndexOf(':') > newSelector.lastIndexOf('::') + 1;
}

// find by same deepness - sort by abc:
function isSameDeepnessAndMinimalMatch(target: string, newSelector: string): boolean {
    const targetParts = target.split('::');
    const newSelectorParts = newSelector.split('::');

    if (targetParts.length !== newSelectorParts.length) {
        return false;
    }
    return newSelectorParts.findIndex((part, index) => part !== targetParts[index]) > 0;
}

export const findRuleBySameDeepness = (selectors: string[], newSelector: string): number | undefined => {
    let abcDeepness = 0;
    let sameDeepnessFound = false;
    selectors.forEach((selector, index) => {
        if (isSameDeepnessAndMinimalMatch(selector, newSelector)) {
            sameDeepnessFound = true;

            if (selector < newSelector) {
                abcDeepness = index;
            }
        }
    });

    if (sameDeepnessFound) {
        if (abcDeepness === 0 && selectors[0] > newSelector) {
            return -1;
        } else if (abcDeepness === 0) {
            return 0;
        }

        return abcDeepness;
    }

    return undefined;
};

function findMatchSize(target: string, newSelector: string): number {
    const targetParts = target.split('::');
    const newSelectorParts = newSelector.split('::');

    if (targetParts.length >= newSelectorParts.length) {
        return 0;
    }
    return newSelectorParts.findIndex((part, index) => part !== targetParts[index]);
}

export const findRuleByClosestAncestor = (selectors: string[], newSelector: string): number | undefined => {
    let maxMatch = 0;
    let match;
    // find by closest ancestor:
    selectors.forEach((selector, index) => {
        const matchSize = findMatchSize(selector, newSelector);
        if (matchSize > maxMatch) {
            maxMatch = matchSize;
            match = index;
        }
    });
    return match;
};

export const findRuleByDeepness = (selectors: string[], newSelector: string) => {
    // find by deepness - TODO remove?
    const deepnessArray = selectors.map((selector) => {
        return (selector.match(/::/g) || []).length;
    });
    const selectorDeepness = (newSelector.match(/::/g) || []).length;
    // take last of same deepness:
    for (let i = deepnessArray.length - 1; i >= 0; i--) {
        if (deepnessArray[i] <= selectorDeepness) {
            return i;
        }
    }
    return undefined;
};
