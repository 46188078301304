import type { Value } from 'css-tree';
import type { AnnotatedCssTreeNode } from './shorthand-expand-types';
import type { DeclarationMap } from '../../../types';

export function annotateBackgroundAst(backgroundAst: Value): AnnotatedCssTreeNode<Value> {
    let afterSlash = false;
    let boxAlreadySet = false;

    // First run - tag different nodes
    backgroundAst.children.forEach((child: AnnotatedCssTreeNode) => {
        switch (child.type) {
            case 'Function':
                if (
                    child.name === 'linear-gradient' ||
                    child.name === 'repeating-linear-gradient' ||
                    child.name === 'radial-gradient' ||
                    child.name === 'repeating-radial-gradient' ||
                    child.name === 'image' ||
                    child.name === 'image-set' ||
                    child.name === 'element' ||
                    child.name === 'cross-fade' ||
                    child.name === 'url'
                ) {
                    child.classification = 'background-image';
                } else {
                    child.classification = 'background-color';
                }
                break;
            case 'Identifier':
                if (child.name) {
                    if (child.name.match(/(repeat-x)|(repeat-y)|(((no-repeat)|(repeat)|(space)|(round)))/)) {
                        child.classification = 'background-repeat';
                    } else if (child.name.match(/(left)|(center)|(right)|(top)|(bottom)/)) {
                        // Match background-position
                        child.classification = 'background-position';
                    } else if (child.name.match(/(contain)|(cover)|(auto)/)) {
                        // Match background-size
                        child.classification = 'background-size';
                    } else if (child.name.match(/(scroll)|(fixed)|(local)/)) {
                        // Match background-attachment
                        child.classification = 'background-attachment';
                    } else if (child.name.match(/(padding-box)|(border-box)|(content-box)/)) {
                        if (!boxAlreadySet) {
                            child.classification = 'background-origin';
                            boxAlreadySet = true;
                        } else {
                            child.classification = 'background-clip';
                        }
                    } else {
                        child.classification = 'background-color';
                    }
                }
                break;
            case 'Dimension':
            case 'Percentage':
                if (afterSlash) {
                    child.classification = 'background-size';
                } else {
                    child.classification = 'background-position';
                }
                break;
            case 'Operator':
                if (child.value && child.value === '/') {
                    afterSlash = true;
                }
                return;
            case 'WhiteSpace':
                return;
            case 'Url':
                child.classification = 'background-image';
                break;
            default:
                child.classification = 'background-color';
        }
    });

    return backgroundAst;
}

export function backgroundCorrection(source: DeclarationMap): DeclarationMap {
    // If has no 'clip' but has 'origin' - set clip to origin:
    if (!source['background-clip'] && source['background-origin']) {
        source['background-clip'] = source['background-origin'];
    }
    return source;
}
