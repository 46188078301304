import type {
    SelectorsNode,
    AnySelectorNode,
    ElementNode,
    ClassNode,
    IdNode,
    PseudoClassNode,
    NestedPseudoClassNode,
    UniversalNode,
    AttributeNode,
    CommentNode,
    InvalidNode,
} from 'css-selector-tokenizer';

export function matchSelectorPartsWithStates(astA: SelectorsNode, astB: SelectorsNode) {
    return matchSelectorParts(astA, astB, {
        selectorAFilter: (node: ChunkNode) => !(node.type === 'pseudo-class' || node.type === 'nested-pseudo-class'),
    });
}

export function matchSelectorParts(
    astA: SelectorsNode,
    astB: SelectorsNode,
    {
        selectorBFilter = (_node: ChunkNode) => true as boolean,
        selectorAFilter = (_node: ChunkNode) => true as boolean,
    } = {}
) {
    const a = separateChunks(astA);
    const b = separateChunks(astB);
    if (a.length !== 1) {
        throw new Error('only single selector allowed');
    }
    const firstSelectorAChunks = a[0];
    return b.some((selectorBChunks) => {
        if (firstSelectorAChunks.length !== selectorBChunks.length) {
            return false;
        }
        for (let i = 0; i < firstSelectorAChunks.length; i++) {
            const chunkA = firstSelectorAChunks[i];
            const chunkB = selectorBChunks[i];
            if (chunkA.type !== chunkB.type) {
                return false;
            }
            if (chunkA.operator !== chunkB.operator) {
                return false;
            }
            const filteredFromA: ChunkNode[] = [];
            const nodesA = chunkA.nodes.filter((node) => {
                if (selectorAFilter(node)) {
                    return true;
                } else {
                    filteredFromA.push(node);
                    return false;
                }
            });
            const nodesB = chunkB.nodes
                .filter(selectorBFilter)
                .filter((node) => !filteredFromA.some((nodeA) => isSameNode(nodeA, node)));
            if (nodesA.length !== nodesB.length) {
                return false;
            }
            for (const nodeA of nodesA) {
                if (nodeA.type === 'comment') {
                    continue;
                }
                const found = nodesB.find((nodeB) => isSameNode(nodeA, nodeB));
                if (!found) {
                    return false;
                }
            }
        }
        return true;
    });
}
function isSameNode(nodeA: AnySelectorNode, nodeB: AnySelectorNode): boolean {
    if (nodeA.type !== nodeB.type) {
        return false;
    }
    if (nodeA.name !== nodeB.name) {
        return false;
    }
    if (
        (nodeA.type === 'attribute' && nodeB.type === 'attribute') ||
        (nodeA.type === 'comment' && nodeB.type === 'comment') ||
        (nodeA.type === 'pseudo-class' && nodeB.type === 'pseudo-class')
    ) {
        return nodeA.content === nodeB.content;
    }
    if (
        (nodeA.type === 'invalid' && nodeB.type === 'invalid') ||
        (nodeA.type === 'spacing' && nodeB.type === 'spacing')
    ) {
        return nodeA.value === nodeB.value;
    }
    if (
        (nodeA.type === 'element' && nodeB.type === 'element') ||
        (nodeA.type === 'universal' && nodeB.type === 'universal')
    ) {
        return nodeA.namespace === nodeB.namespace;
    }
    if (nodeA.type === 'operator' && nodeB.type === 'operator') {
        return nodeA.operator === nodeB.operator;
    }
    if (nodeA.type === 'selector' && nodeB.type === 'selector') {
        if (nodeA.nodes.length !== nodeB.nodes.length) {
            return false;
        }
        return nodeA.nodes.every((node, i) => isSameNode(node, nodeB.nodes[i]));
    }
    if (
        (nodeA.type === 'nested-pseudo-class' && nodeB.type === 'nested-pseudo-class') ||
        (nodeA.type === 'selectors' && nodeB.type === 'selectors')
    ) {
        if (nodeA.nodes.length !== nodeB.nodes.length) {
            return false;
        }
        return nodeA.nodes.every((node, i) => isSameNode(node, nodeB.nodes[i]));
    }
    return true;
}
type ChunkNode =
    | ElementNode
    | ClassNode
    | IdNode
    | PseudoClassNode
    | NestedPseudoClassNode
    | UniversalNode
    | AttributeNode
    | CommentNode
    | InvalidNode;
interface SelectorChunk {
    type: string;
    operator?: string;
    nodes: Array<ChunkNode>;
}
function separateChunks(selectorNode: AnySelectorNode) {
    const selectors: SelectorChunk[][] = [];
    walkNodes(selectorNode, (node) => {
        if (node.type === 'selectors') {
            // skip
        } else if (node.type === 'selector') {
            selectors.push([{ type: 'selector', nodes: [] }]);
        } else if (node.type === 'operator') {
            const chunks = selectors[selectors.length - 1];
            chunks.push({ type: node.type, operator: node.operator, nodes: [] });
        } else if (node.type === 'spacing') {
            const chunks = selectors[selectors.length - 1];
            chunks.push({ type: node.type, operator: node.value, nodes: [] });
        } else if (node.type === 'pseudo-element') {
            const chunks = selectors[selectors.length - 1];
            chunks.push({ type: node.type, operator: '::' + node.name, nodes: [] });
        } else {
            const chunks = selectors[selectors.length - 1];
            chunks[chunks.length - 1].nodes.push(node);
        }
    });
    return selectors;
}

export function walkNodes(
    node: AnySelectorNode,
    visitor: (node: AnySelectorNode, index: number, nodes: AnySelectorNode[]) => boolean | void,
    index = 0,
    nodes: AnySelectorNode[] = [node]
): boolean | void {
    if (!node) {
        return;
    }
    let doNext = visitor(node, index, nodes);
    if (doNext === false) {
        return false;
    }
    if (doNext === true) {
        return true;
    }
    if ('nodes' in node) {
        for (let i = 0; i < node.nodes.length; i++) {
            doNext = walkNodes(node.nodes[i], visitor, i, node.nodes);
            if (doNext === false) {
                return false;
            }
        }
    }
}
