import deIndent from 'de-indent';
import type { PanelComponentsMetadata, EditorComponentConfig } from '../types';
export const stFile = ['/button-presets/a.st.css', '/button-presets/b.st.css'];

export const BASE_BTN_PRESETS = [{ path: stFile[0] }, { path: stFile[1] }];

export const deindent = (literals: TemplateStringsArray, ...placeholders: string[]): string => {
    let result = '';

    // interleave the literals with the placeholders
    for (let i = 0; i < placeholders.length; i++) {
        result += literals[i];
        result += placeholders[i];
    }

    // add the last literal
    result += literals[literals.length - 1];

    return deIndent(result[0] === '\n' ? result.slice(1) : result);
};

export const normalizeBackgroundProp = (backgroundValue: string) => {
    if (backgroundValue === undefined) {
        return undefined;
    }
    return (
        backgroundValue
            // Background normalization
            .replace(/url\('(.*)'\)/g, 'url("$1")')
            // Browser compatibility
            .replace(/, */g, ', ')
            .replace(/ +/g, ' ')
    );
};

export const setupStylableDriverWithPresets = (stylableDriver: any) => {
    // init preset driver with mock data
    stylableDriver.writeFile(
        '/button-mock.st.css',
        deindent`
        .root{

        }

        .label{

        }
    `
    );
    stylableDriver.writeFile(
        stFile[0],
        deindent`
                        :import{
                            -st-from: '../button-mock.st.css';
                            -st-default: Button;
                        }
                        :vars {
                            color1: aliceblue;
                            color2: black;
                            width1: 2px;
                        }
                        .root {
                             background-color: value(color1);
                             color: value(color2);
                             border: value(width1) solid black;
                             border-radius:10px;
                             -st-extends: Button;
                        }
                        .root::label {
                            background-color: green;
                        }
                    `
    );
    stylableDriver.writeFile(
        stFile[1],
        deindent`
                        :import{
                            -st-from: '../button-mock.st.css';
                            -st-default: Button;
                        }
                        :vars {
                            color1: cadetblue;
                            color2: black;
                            width1: 0;
                        }
                        .root {
                            background-color: value(color1);
                            color: value(color2);
                            border: value(width1) solid blue;
                            -st-extends: Button;
                        }
                    `
    );

    return stylableDriver;
};

export const mockConfig = (fs: PanelComponentsMetadata['fs']) => {
    return {
        version: '0.0.1',
        name: 'irrelevant',
        fs,
        components: {
            Button: {
                namespace: 'Mock',
                stylesheetPath: '/button.st.css',
                variantsPath: '',
                id: 'Button',
                selectorConfiguration: {
                    '.root::label': {
                        primeElementSelector: '.root',
                    },
                },
            },
            ButtonSVGLabel: {
                namespace: 'Mock',
                stylesheetPath: '',
                variantsPath: '',
                id: 'ButtonSVGLabel',
            },
        },
        packages: {},
    };
};

export const mockFs = {
    '/site.st.css': {
        metadata: {
            depth: 0,
            namespace: 'x',
        },
        content: deindent`
                :import {
                    -st-from: "./button.st.css";
                    -st-default: Button;
                }
                :import {
                    -st-from: "/button22.st.css";
                    -st-default: ButtonSVGLabel;
                }
                :vars {
                    user_0: green;
                    gradient_0: linear-gradient(red, blue);
                }
                .button1{
                    -st-extends: Button;
                    -archetype: box;
                    border-radius: 10px;
                    background-color: red;
                }
                .button1:hover {
                    background-color: blue;
                }
                .button2{
                    -st-extends: Button;
                    border-radius: 10px;
                }
                .button22{
                    -st-extends: ButtonSVGLabel;
                    border-radius: 10px;
                }

            `,
    },
    '/button.st.css': {
        metadata: {
            depth: 0,
            namespace: 'x',
        },
        content: deindent`
                .root{
                }
                .label{
                }
            `,
    },
    '/newButton1.st.css': {
        metadata: {
            depth: 0,
            namespace: 'x',
        },
        content: deindent`
                .button1{
                    background-color: green;
                }
            `,
    },
    '/button2.st.css': {
        metadata: {
            depth: 0,
            namespace: 'x',
        },
        content: deindent`
                .root{
                    background-color: red;
                }
            `,
    },
    '/button22.st.css': {
        metadata: {
            depth: 0,
            namespace: 'x',
        },
        content: deindent`
                    .root{
                        background-color: red;
                    }
                    .label {}
                    .icon {}
                `,
    },
    '/presets.st.css': {
        metadata: {
            depth: 0,
            namespace: 'x',
        },
        content: deindent`
                :import {
                    -st-from: "./button.st.css";
                    -st-default: Button;
                }
                .preset1{
                    -st-extends: Button;
                    color: pink;
                }
                :import {
                    -st-from: "./button22.st.css";
                    -st-default: ButtonSVGLabel;
                }
                .preset2{
                    -st-extends: ButtonSVGLabel;
                    color: pink;
                }
            `,
    },
};

export const mockExternalButtonConfig: EditorComponentConfig = {
    namespace: 'Mock',
    stylesheetPath: '/button.st.css',
    variantsPath: '',
    id: 'Button',
    selectorConfiguration: {
        '.root::label': { hide: true },
    },
};
