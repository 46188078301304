import { annotateBackgroundAst } from './background-grouper';
import type { CssGrouper } from './shorthand-expand-types';
import { annotateTextDecorationAst } from './text-decoration-grouper';
import { annotateTextEmphasisAst } from './text-emphasis-grouper';
import { backgroundCorrection } from './background-grouper';

/*
configures which css props ('prop') will be grouped and expanded using a specified logic instead of the default browser expand
 */
export const cssGrouperConfig: CssGrouper[] = [
    {
        prop: 'background',
        annotate: annotateBackgroundAst,
        transform: backgroundCorrection,
    },
    {
        prop: 'text-decoration',
        annotate: annotateTextDecorationAst,
    },
    {
        prop: 'text-emphasis',
        annotate: annotateTextEmphasisAst,
    },
];
