import type { AnnotatedCssTreeNode } from './shorthand-expand-types';
import type { Value } from 'css-tree';

export function annotateTextEmphasisAst(ast: Value): AnnotatedCssTreeNode<Value> {
    ast.children.forEach((child: AnnotatedCssTreeNode) => {
        switch (child.type) {
            case 'Function':
                child.classification = 'text-emphasis-color';
                break;
            case 'String':
                child.classification = 'text-emphasis-style';
                break;
            case 'Identifier':
                if (child.name) {
                    if (child.name.match(/(none)|(filled)|(open)|(dot)|(circle)|(double-circle)|(triangle)|(sesame)/)) {
                        child.classification = 'text-emphasis-style';
                    } else {
                        child.classification = 'text-emphasis-color';
                    }
                }
                break;
            case 'Operator':
                return;
            case 'WhiteSpace':
                return;
            default:
                child.classification = 'text-emphasis-color';
        }
    });

    return ast;
}
